import React, { ReactNode } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import { isHasAccess } from 'features/validateAccess';

import { positionsService } from 'entities/position';
import { withSuspense } from 'shared/lib/react';

import ListWithPagination from 'components/ListWithPagination/ListWithPagination';
import { PositionSkeletonList } from 'components/SkeletonList/PositionSkeletonList';

import { PAGE_SIZE } from '../Position.consts';
import {
  getAssignmentCreatePermissions,
  getAssignmentListPermissions,
  getDemandCreatePermissions,
  getDemandListPermissions,
  getEitAllPositions,
} from '../Position.permissions';
import PositionListItem from './components/PositionListItem/PositionListItem';

import styles from './PositionList.module.scss';

interface PositionListProps {
  projectId: string;
  accountId: string;
  page: number;
  expiredIncluded: boolean;
  setPage: (value: number) => void;
}

const PositionList = ({ page, projectId, setPage, expiredIncluded, accountId }: PositionListProps): JSX.Element => {
  const { data: listData } = useSuspenseQuery(
    positionsService.queryOptions({
      projectId,
      page: page - 1,
      size: PAGE_SIZE,
      showArchived: false,
      expiredIncluded,
    }),
  );

  const renderPositionsList = (): ReactNode => {
    const canEditAllPositions = isHasAccess(getEitAllPositions(accountId, projectId));
    const canSeeAssignments = isHasAccess(getAssignmentListPermissions(accountId, projectId));
    const canSeeDemands = isHasAccess(getDemandListPermissions(accountId, projectId));

    const canCreateAssignment = isHasAccess(getAssignmentCreatePermissions(accountId, projectId));
    const canCreateDemand = isHasAccess(getDemandCreatePermissions(accountId, projectId));

    return listData.content.map((item) => {
      return (
        <li key={item.id} className={styles.accordionItem}>
          <PositionListItem
            accountId={accountId}
            position={item}
            showEditButton={canEditAllPositions}
            showCreateAssignmentButton={canCreateAssignment}
            showCreateDemandButton={canCreateDemand}
            showAssignments={canSeeAssignments}
            showDemands={canSeeDemands}
          />
        </li>
      );
    });
  };

  return (
    <ListWithPagination
      items={renderPositionsList()}
      totalItems={listData.totalElements}
      pageSize={PAGE_SIZE}
      page={page}
      setPage={setPage}
    />
  );
};

export default withSuspense(PositionList, {
  fallback: <PositionSkeletonList />,
});
