import React, { useCallback, useEffect, useState } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useForm } from 'antd/lib/form/Form';
import { Store } from 'antd/lib/form/interface';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { isHasAccess } from 'features/validateAccess';
import { demandParentInfoService, useCreateDemandMutation } from 'entities/demand';
import { positionService } from 'entities/position';

import { DEFAULT_DATE_FORMAT_FOR_REQUESTS } from 'shared/config';
import { convertDayJsToString, convertToDayJs } from 'shared/lib/dayjs';
import { withSuspense } from 'shared/lib/react';
import { pathKeys } from 'shared/lib/react-router';
import { Loader } from 'shared/ui/Loader';

import CreateUpdateFormHolder from 'components/CreateUpdateFormHolder/CreateUpdateFormHolder';
import { DEFAULT_STATUS_VALUE } from 'components/Demand/Demand.consts';
import DemandCreateUpdateFormItems from 'components/Demand/DemandCreateUpdateFormItems/DemandCreateUpdateFormItems';

import { getDemandReadPermission } from '../Demand.permissions';

type DemandCreateFormProps = { accountId: string; projectId: string; positionId: string };

const DemandCreateForm: React.FC<DemandCreateFormProps> = ({ accountId, projectId, positionId }) => {
  const { t } = useTranslation();

  const [form] = useForm();

  const navigate = useNavigate();

  const [preventNavigation, setPreventNavigation] = useState(false);

  const canSeeDemandParentInfo = isHasAccess(getDemandReadPermission(accountId, projectId));

  const { data: position } = useSuspenseQuery(positionService.queryOptions(positionId));

  const { data: demandParentInfo } = useSuspenseQuery(
    demandParentInfoService.queryOptions(positionId, canSeeDemandParentInfo),
  );

  const {
    mutateAsync: createDemandMutation,
    isPending: isCreating,
    isError: hasCreatingErrors,
  } = useCreateDemandMutation(accountId, projectId);

  const creationDate = dayjs();

  const checkFormValuesModified = useCallback((): void => {
    const values = form.getFieldsValue();

    const isStatusChanged = values.status !== DEFAULT_STATUS_VALUE;
    const isDesirableDateChanged = !!values.desirableDate;

    setPreventNavigation(isStatusChanged || isDesirableDateChanged);
  }, [form]);

  useEffect(() => {
    if (hasCreatingErrors) {
      checkFormValuesModified();
    }
  }, [checkFormValuesModified, hasCreatingErrors]);

  const onFormSubmit = (values: Store): void => {
    setPreventNavigation(false);
    const { desirableDate, status } = values;

    const demandDesirableDate = convertDayJsToString(desirableDate, DEFAULT_DATE_FORMAT_FOR_REQUESTS);

    createDemandMutation({
      status,
      desirableDate: demandDesirableDate,
      positionId,
    });
  };

  return (
    <div data-testid="demand-create-form">
      <CreateUpdateFormHolder
        title={t('Demand:DemandCreateForm.heading')}
        subtitle={position?.name}
        onFormSubmit={onFormSubmit}
        onCancel={() => navigate(pathKeys.position.root({ accountId, projectId }))}
        isLoading={isCreating}
        customFormControl={[form]}
        onFormChange={checkFormValuesModified}
        preventNavigation={preventNavigation}
        initialData={{
          status: DEFAULT_STATUS_VALUE,
        }}
        data-testid="demand-create-form"
      >
        <DemandCreateUpdateFormItems
          hideRecruiter
          isLoading={isCreating}
          positionStartDate={convertToDayJs(position?.startDate)}
          positionEndDate={convertToDayJs(position?.endDate)}
          accountDescription={demandParentInfo.accountDescription ?? ''}
          projectDescription={demandParentInfo.projectDescription ?? ''}
          positionDescription={demandParentInfo.positionDescription ?? ''}
          creationDate={creationDate}
        />
      </CreateUpdateFormHolder>
    </div>
  );
};

export default withSuspense(DemandCreateForm, { fallback: <Loader /> });
