import React, { useCallback } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import { FoldingTable } from 'features/FoldingTable';
import { TransferModal } from 'features/moveUsersBetweenGroups';

import { accessGroupsService } from 'entities/accessGroup';
import { permissionListService } from 'entities/permission';

import { withSuspense } from 'shared/lib/react';
import { useBlockNavigation } from 'shared/lib/react-router';
import { Loader } from 'shared/ui/Loader';

import { useAccess } from './lib/useAccess';
import { useChanges } from './lib/useChanges';
import { WidgetType, useColumns } from './lib/useColumns';
import { usePinnedColumns } from './lib/usePinnedColumns';
import { useSidebar } from './lib/useSidebar';
import ColumnMenu from './ui/ColumnMenu/ColumnMenu';
import GrantTypeSelector from './ui/GrantTypeSelector/GrantTypeSelector';
import PopUpButton from './ui/PopUpButton/PopUpButton';

import styles from './AccessGroupsManagementTable.module.scss';

const MAX_PINNED_COLUMNS = 3;

function AccessGroupsManagementTable(): JSX.Element {
  const { data: permissions } = useSuspenseQuery(permissionListService.queryOptions());

  const { data: accessGroupListData } = useSuspenseQuery(accessGroupsService.queryOptions());

  const { createAccess, editAccess, archiveAccess } = useAccess();

  const mappedSidebar = useSidebar(permissions);
  const { columns, onCloseTransferModal, onSaveTransferModal } = useColumns(accessGroupListData, mappedSidebar);
  const {
    columnsStatuses,
    hasUnsavedChanges,
    openDuplicateModal,
    editAccessGroup,
    onChangePin,
    onChangeGrantType,
    onSavePermissionChanges,
    openCreateAccessGroupModal,
    openDeleteModal,
    openRenameModal,
    openDropUnsavedChangesModal,
  } = useChanges(accessGroupListData);

  const { pinnedColumns } = usePinnedColumns(accessGroupListData);

  useBlockNavigation(hasUnsavedChanges);

  const renderHeadingMenu = useCallback(
    (id: string) => {
      return (
        <ColumnMenu
          id={id}
          disabled={!editAccess && !archiveAccess && !createAccess}
          onEdit={editAccess ? editAccessGroup : undefined}
          onDelete={archiveAccess ? openDeleteModal : undefined}
          onDuplicate={createAccess ? openDuplicateModal : undefined}
          onRename={editAccess ? openRenameModal : undefined}
        />
      );
    },
    [archiveAccess, createAccess, editAccess, editAccessGroup, openDeleteModal, openDuplicateModal, openRenameModal],
  );

  const renderWidget = useCallback(
    (_: string, data: WidgetType, disabled: boolean) => {
      return (
        <GrantTypeSelector
          disabled={disabled}
          onChangeGrantType={(value) => onChangeGrantType(data.accessGroupId, data.permissionId, value, data.version)}
          initialGrantType={data.grantType}
          allowedGrantTypes={
            permissions.find((permission) => permission.id === data.permissionId)?.allowedGrantTypes ?? []
          }
        />
      );
    },
    [onChangeGrantType, permissions],
  );

  return (
    <div className={styles.rootWrapper} data-testid="access-groups">
      <FoldingTable
        columns={columns}
        isColumnDraggable={false}
        sidebar={mappedSidebar}
        maxPinnedColumnsCount={MAX_PINNED_COLUMNS}
        renderWidget={renderWidget}
        renderHeadingMenu={renderHeadingMenu}
        onChangePin={onChangePin}
        initialPinnedColumnKeys={pinnedColumns}
        columnsStatuses={columnsStatuses}
      />
      <PopUpButton
        editMode={hasUnsavedChanges}
        onCreate={createAccess ? openCreateAccessGroupModal : undefined}
        onSave={editAccess ? onSavePermissionChanges : undefined}
        onCancelSave={openDropUnsavedChangesModal}
      />
      <TransferModal onClose={onCloseTransferModal} onSave={onSaveTransferModal} />
    </div>
  );
}

export default withSuspense(AccessGroupsManagementTable, { fallback: <Loader /> });
