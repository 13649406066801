import { useCallback } from 'react';
import { App } from 'antd';
import { useTranslation } from 'react-i18next';

import { useRemoveAGMutation } from 'entities/accessGroup';

export const useDeleteModal = (): ((id: string, name: string) => void) => {
  const { modal } = App.useApp();
  const { t } = useTranslation('AccessGroup');

  const { mutateAsync: removeAGMutation } = useRemoveAGMutation();

  const openDeleteModal = useCallback(
    (id: string, name: string): void => {
      const instance = modal.confirm({
        content: t('DeleteModal.title', { group: name }),
        transitionName: '',
        centered: true,
        closable: true,
        cancelText: t('DeleteModal.cancelButton.text'),
        okText: t('DeleteModal.confirmButton.text'),
        onOk: () => {
          instance.update({
            okButtonProps: { loading: true },
          });

          removeAGMutation(id, {
            onSettled: () => instance.destroy(),
          });
          return Promise.reject();
        },
      });
    },
    [modal, removeAGMutation, t],
  );

  return openDeleteModal;
};
