import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { isHasAccess, pathPermissions } from 'features/validateAccess';
import { accountService } from 'entities/account';

import { ListPageLayout } from 'shared/layouts/pageLevelLayouts';
import { pathKeys } from 'shared/lib/react-router';
import type { IBreadcrumbItem } from 'shared/ui/Breadcrumb';
import ProjectCheckBoxMenu from 'components/Project/ProjectCheckBoxMenu/ProjectCheckBoxMenu';
import { useProjectListParams } from 'components/Project/ProjectCheckBoxMenu/useProjectListParams';
import ProjectsList from 'components/Project/ProjectsList/ProjectsList';

import { getAccountNameViewPermission, getProjectCreatePermissions } from './ProjectsPage.permissions';

const ProjectsPage: React.FC = () => {
  const { t } = useTranslation();

  const { hideNonActive, setPage, page, showArchived, onChangeHiddenExpiredProjects, onChangeHiddenArchivedProjects } =
    useProjectListParams();

  const { accountId = '' } = useParams();

  const canCreateProject = isHasAccess(getProjectCreatePermissions(accountId));
  const canGoToAccountList = isHasAccess(pathPermissions.account.root);
  const canSeeAccountName = isHasAccess(getAccountNameViewPermission(accountId));

  const { data: account, isLoading } = useQuery(accountService.queryOptions(accountId, canSeeAccountName));

  const createBreadcrumb = (): IBreadcrumbItem[] => {
    return [
      {
        label: t('Account:AccountsPage.heading'),
        path: pathKeys.account.root(),
        disable: !canGoToAccountList,
      },
      {
        label: account?.name ?? ' ',
        hide: !canSeeAccountName,
      },
    ];
  };

  return (
    <ListPageLayout
      title={t('Project:ProjectsPage.heading')}
      breadcrumb={createBreadcrumb()}
      createButtonText={t('Project:ProjectsPage.createButton.label')}
      isBreadcrumbLoading={isLoading}
      hideCreateButton={!canCreateProject}
      createPath={() => pathKeys.project.create({ accountId })}
      checkBoxMenu={
        <ProjectCheckBoxMenu
          onChangeShowArchived={onChangeHiddenArchivedProjects}
          onChangeHiddenExpiredProjects={onChangeHiddenExpiredProjects}
          showArchived={showArchived}
          hideNonActive={hideNonActive}
        />
      }
    >
      <ProjectsList
        accountId={accountId}
        hideNonActive={hideNonActive}
        showArchived={showArchived}
        setPage={setPage}
        page={page}
      />
    </ListPageLayout>
  );
};

export default ProjectsPage;
