import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useTranslation } from 'react-i18next';

import styles from '../ProjectsList/ProjectsList.module.scss';

interface CheckBoxMenuProps {
  onChangeShowArchived: (event: CheckboxChangeEvent) => void;
  hideNonActive: boolean;
  showArchived: boolean;
  onChangeHiddenExpiredProjects: (event: CheckboxChangeEvent) => void;
}

const ProjectCheckBoxMenu = ({
  onChangeShowArchived,
  hideNonActive,
  showArchived,
  onChangeHiddenExpiredProjects,
}: CheckBoxMenuProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.rootWrapper}>
      <div className={styles.filtersWrapper}>
        <Checkbox onChange={onChangeHiddenExpiredProjects} checked={hideNonActive}>
          {t('Project:ProjectsList.filters.hideNonActive')}
        </Checkbox>
        <Checkbox onChange={onChangeShowArchived} checked={showArchived}>
          {t('Project:ProjectsList.filters.showArchived')}
        </Checkbox>
      </div>
    </div>
  );
};

export default ProjectCheckBoxMenu;
