import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useTranslation } from 'react-i18next';

import styles from '../PositionList/PositionList.module.scss';

interface CheckBoxMenuProps {
  expiredIncluded: boolean;
  onChangeHiddenExpiredPositions: (event: CheckboxChangeEvent) => void;
}
const PositionCheckBoxMenu = ({ onChangeHiddenExpiredPositions, expiredIncluded }: CheckBoxMenuProps): JSX.Element => {
  const { t } = useTranslation('Position');

  return (
    <div className={styles.rootWrapper} data-testid="positions-and-assignments-list">
      <div className={styles.filtersWrapper}>
        <Checkbox onChange={onChangeHiddenExpiredPositions} checked={expiredIncluded}>
          {t('PositionsAndAssignmentsList.filters.hideExpired')}
        </Checkbox>
      </div>
    </div>
  );
};

export default PositionCheckBoxMenu;
