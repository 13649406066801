import { Flex, Skeleton } from 'antd';

import styles from './SkeletonList.module.scss';

function PositionSkeletonList(): JSX.Element {
  return (
    <Flex vertical gap="large" className={styles.skeletonWrapper}>
      {Array.from({ length: 10 }, (_, index) => (
        <Flex gap="large" align="center" key={index}>
          <Flex gap="large" align="center" className={styles.skeletonInputWidth}>
            <Skeleton.Input active size="small" block />
            <Skeleton.Input active size="small" block />
          </Flex>
          <Flex gap={200} justify="space-between">
            <Skeleton.Input active size="small" />
            <Skeleton.Avatar active size="small" />
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
}

export { PositionSkeletonList };
