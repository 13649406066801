import { QueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 3,
    },
    mutations: {
      throwOnError(error) {
        if (isAxiosError(error)) {
          return error.response?.status === 401 || error.response?.status === 403;
        }
        return true;
      },
    },
  },
});
