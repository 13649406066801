import React, { Fragment, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Skeleton, Tooltip } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { isHasAccess } from 'features/validateAccess';
import {
  assignmentUserInvolvementReportService,
  type AssignmentsInvolvementReport,
  type UserInvolvementReportDTO,
} from 'entities/assignment';

import { DEFAULT_DATE_FORMAT_FOR_REQUESTS, DEFAULT_DATE_FORMAT_UI } from 'shared/config';
import { convertDayJsToString } from 'shared/lib/dayjs';
import { pathKeys } from 'shared/lib/react-router';
import { Timeline, type TimelineItem } from 'shared/ui/Timeline';

import { getMonthsArray } from '../../lib/getMonthsArray';
import { LINK_POSITION_GLOBAL, getLinkPositionLocal } from '../../permissions';
import { ControlPanel } from '../TimelinesControlPanel/TimelinesControlPanel';

import styles from './InvolvementTimelines.module.scss';

interface IInvolvementTimelinesProps {
  userId: string;
}

export const InvolvementTimelines: React.FC<IInvolvementTimelinesProps> = (props) => {
  const { userId } = props;

  const { t } = useTranslation();

  const [quantityMonth, setQuantityMonth] = useState(3);
  const [monthOffset, setMonthOffset] = useState(0);

  const startDate = useMemo(() => dayjs().startOf('month').add(monthOffset, 'month'), [monthOffset]);

  const endDate = useMemo(
    () =>
      startDate
        .clone()
        .add(quantityMonth - 1, 'months')
        .endOf('month'),
    [startDate, quantityMonth],
  );

  const { data: involvementsData, isLoading: isInvolvementsLoading } = useQuery(
    assignmentUserInvolvementReportService.queryOptions({
      userId,
      startDate: convertDayJsToString(startDate, DEFAULT_DATE_FORMAT_FOR_REQUESTS),
      endDate: convertDayJsToString(endDate, DEFAULT_DATE_FORMAT_FOR_REQUESTS),
    }),
  );

  const nextMonth = (): void => {
    setMonthOffset((prev) => prev + 1);
  };

  const prevMonth = (): void => {
    setMonthOffset((prev) => prev - 1);
  };

  const returnToCurrentDate = (): void => {
    setMonthOffset(0);
  };

  const renderMonths = (): React.ReactNode => {
    const months = getMonthsArray(startDate, endDate);

    return (
      <React.Fragment>
        {months.map((month) => {
          return (
            <div
              key={month.name}
              className={classNames(styles.monthBlock, {
                [styles.currentMonth]: month.isCurrentMonth,
              })}
              style={{ width: `${month.percentage}%` }}
            >
              {month.name}
            </div>
          );
        })}
      </React.Fragment>
    );
  };

  const buildTooltipContent = (involvement: UserInvolvementReportDTO['availability'][0]): React.ReactNode => {
    const startDateFormatted = dayjs(involvement.startDate).format(DEFAULT_DATE_FORMAT_UI);
    const endDateFormatted = dayjs(involvement.endDate).format(DEFAULT_DATE_FORMAT_UI);
    return (
      <React.Fragment>
        <div>{`${involvement.value}%`}</div>
        <div>{`${startDateFormatted} - ${endDateFormatted}`}</div>
      </React.Fragment>
    );
  };

  const skeletonTimelines = (): React.ReactNode => {
    return (
      <div className={styles.timelineSkeleton} data-testid="skeleton-timelines">
        <Skeleton paragraph={false} title={{ width: '100%' }} active />
        <Skeleton paragraph={false} title={{ width: '100%' }} active />
        <Skeleton paragraph={false} title={{ width: '100%' }} active />
        <Skeleton paragraph={false} title={{ width: '100%' }} active />
      </div>
    );
  };

  const renderTimelines = (): React.ReactNode => {
    const { availability, assignments } = involvementsData;

    const groupedAssignments: {
      [accountId: string]: {
        accountName: string;
        projects: { [ProjectId: string]: { projectName: string; assignments: AssignmentsInvolvementReport[] } };
      };
    } = {};

    const canSeeAllPositionLinks = isHasAccess(LINK_POSITION_GLOBAL);

    assignments.forEach((assignment) => {
      const accountId = assignment.account.id;
      const projectId = assignment.project.id;

      if (!groupedAssignments[accountId]) {
        groupedAssignments[accountId] = {
          accountName: assignment.account.name,
          projects: {},
        };
      }
      if (!groupedAssignments[accountId].projects[projectId]) {
        groupedAssignments[accountId].projects[projectId] = {
          projectName: assignment.project.name,
          assignments: [],
        };
      }

      groupedAssignments[accountId].projects[projectId].assignments.push(assignment);
    });

    const mainTimelines = Object.entries(groupedAssignments).map(([accountId, accountContent]) => {
      const timelinesForAccount = Object.entries(accountContent.projects).map(([projectId, projectContent]) => {
        const canSeeLink = canSeeAllPositionLinks || isHasAccess(getLinkPositionLocal(accountId, projectId));

        const timelinesForProject = projectContent.assignments.map((item) => {
          const { involvements } = item;
          const timeline: TimelineItem[] = involvements
            .filter((involvement) => involvement.value !== 0)
            .map((involvement) => {
              const involvementStart = dayjs(involvement.startDate).isBefore(startDate)
                ? startDate
                : dayjs(involvement.startDate);
              const involvementEnd = dayjs(involvement.endDate).isAfter(endDate) ? endDate : dayjs(involvement.endDate);

              return {
                startDate: involvementStart,
                endDate: involvementEnd,
                children: (
                  <Tooltip overlay={buildTooltipContent(involvement)}>
                    <div className={styles.timelineContent}>{`${involvement.value}%`}</div>
                  </Tooltip>
                ),
              };
            });

          const content = (
            <Fragment>
              <div className={styles.timelineInfo}>
                <Tooltip overlay={item.position.name}>
                  <div className={styles.positionName}>{item.position.name}</div>
                </Tooltip>
              </div>
              <div className={styles.timeline} data-testid="timeline-item">
                <Timeline minDate={startDate} maxDate={endDate} timeline={timeline} />
              </div>
            </Fragment>
          );

          return canSeeLink ? (
            <Link
              key={item.position.id}
              to={pathKeys.position.root({ accountId: item.account.id, projectId: item.project.id })}
              className={classNames(styles.timelineWrapper, styles.link)}
            >
              {content}
            </Link>
          ) : (
            <span key={item.position.id} className={classNames(styles.timelineWrapper)}>
              {content}
            </span>
          );
        });

        return (
          <div key={projectId} className={styles.projectTimelines}>
            <Tooltip overlay={projectContent.projectName}>
              <div className={styles.projectName}>{projectContent.projectName}</div>
            </Tooltip>
            {timelinesForProject}
          </div>
        );
      });

      return (
        <div key={accountId} className={styles.accountTimelines}>
          <Tooltip overlay={accountContent.accountName}>
            <div className={styles.accountName}>{accountContent.accountName}</div>
          </Tooltip>
          {timelinesForAccount}
        </div>
      );
    });

    const availabilityTimeline = (
      <div key="availability" className={styles.timelineWrapper}>
        <div className={styles.timelineInfo}>{t('Assignment:UserCard.Timelines.Available')}</div>
        <div className={styles.timeline}>
          <Timeline
            minDate={startDate}
            timeline={availability
              .filter((item) => item.value !== 0)
              .map((item) => {
                return {
                  startDate: dayjs(item.startDate).isBefore(startDate) ? startDate : dayjs(item.startDate),
                  endDate: dayjs(item.endDate).isAfter(endDate) ? endDate : dayjs(item.endDate),
                  children: (
                    <Tooltip overlay={buildTooltipContent(item)}>
                      <div className={styles.timelineContent}>{`${item.value}%`}</div>
                    </Tooltip>
                  ),
                };
              })}
            maxDate={endDate}
          />
        </div>
      </div>
    );

    return (
      <React.Fragment>
        {mainTimelines}
        {availabilityTimeline}
      </React.Fragment>
    );
  };

  return (
    <div className={styles.container}>
      <ControlPanel
        quantityMonth={quantityMonth}
        setQuantityMonth={setQuantityMonth}
        prevMonth={prevMonth}
        nextMonth={nextMonth}
        returnToCurrentDate={returnToCurrentDate}
      />
      <div className={styles.rootWrapper}>
        {isInvolvementsLoading || !involvementsData ? (
          <div className={styles.timelinesWrapper}>{skeletonTimelines()}</div>
        ) : (
          <React.Fragment>
            <div className={styles.monthsWrapper}>{renderMonths()}</div>
            <div className={styles.timelinesWrapper}>{renderTimelines()}</div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
