import React, { useCallback, useEffect, useState } from 'react';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { Button, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ArchiveEntityModal } from 'features/archiveEntity';
import { LocalPermissionMapper } from 'features/LocalPermissionMapper';
import { isHasAccess } from 'features/validateAccess';
import {
  useAddLocalMappingAGMutation,
  useRemoveLocalMappingAGMutation,
  AccessGroupMappingRequest,
} from 'entities/accessGroup';
import { accountService } from 'entities/account';
import { projectService, useArchiveProjectMutation, useUpdateProjectMutation } from 'entities/project';
import { localMappedUsersService, MappedUserDTO } from 'entities/user';

import { withSuspense } from 'shared/lib/react';
import { pathKeys } from 'shared/lib/react-router';
import { Loader } from 'shared/ui/Loader';

import CreateUpdateFormHolder from 'components/CreateUpdateFormHolder/CreateUpdateFormHolder';
import ProjectCreateUpdateFormItems from 'components/Project/ProjectCreateUpdateFormItems/ProjectCreateUpdateFormItems';
import UserListWithGroup from 'components/UserListWithGroup/userListWithGroup';

import {
  getLocalMappingPermissions,
  getAccountNameViewPermission,
  getProjectArchivePermission,
} from '../Project.permissions';

import styles from './ProjectUpdateForm.module.scss';

type FormType = {
  name: string;
  description: string;
};

const ProjectUpdateForm: React.FC<{ accountId: string; projectId: string }> = ({ accountId, projectId }) => {
  const { t } = useTranslation('Project');
  const [visibleArchiveDialog, setVisibleArchiveDialog] = useState(false);
  const [preventNavigation, setPreventNavigation] = useState(false);
  const [localPermissionsModal, setLocalPermissionsModal] = useState(false);

  const { mutateAsync: addLocalMappingMutation, isPending: isLocalMappingAdding } = useAddLocalMappingAGMutation();
  const { mutateAsync: removeLocalMappingMutation, isPending: isLocalMappingRemoving } =
    useRemoveLocalMappingAGMutation();

  const navigate = useNavigate();

  const [form] = useForm<FormType>();

  const {
    mutateAsync: updateProjectMutation,
    isPending: isUpdating,
    isError: hasUpdatingErrors,
    error: updatingErrors,
    reset: resetUpdateProject,
  } = useUpdateProjectMutation(projectId);

  const { data: project } = useSuspenseQuery(projectService.queryOptions(projectId));

  const {
    mutateAsync: archiveProjectMutation,
    isPending: isArchiving,
    error: archivingErrors,
    isError: hasArchivingErrors,
    reset: resetArchiveProject,
  } = useArchiveProjectMutation(projectId);

  const canLocalMapProject = isHasAccess(getLocalMappingPermissions(accountId, projectId));
  const canSeeAccountName = isHasAccess(getAccountNameViewPermission(accountId));
  const canArchiveProject = isHasAccess(getProjectArchivePermission(accountId, projectId));

  const { data: localMappedUsers, refetch: refetchLocalMappedUsers } = useQuery(
    localMappedUsersService.queryOptions({ entityType: 'PROJECT', entityId: projectId }, canLocalMapProject),
  );

  const { data: account } = useQuery(accountService.queryOptions(accountId, canSeeAccountName));

  useEffect(() => {
    if (hasUpdatingErrors && updatingErrors?.response?.data.code === 'ItemAlreadyExistsException') {
      form.setFields([
        { name: 'name', errors: [t('ProjectCreateUpdateFormItems.name.item.validationMessage.nonUniqueName')] },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUpdatingErrors]);

  const initData = useCallback((): FormType => {
    return {
      name: project.name,
      description: project.description,
    };
  }, [project]);

  const archiveProject = (): void => {
    setPreventNavigation(false);
    archiveProjectMutation(project.id);
  };

  const closeModal = (): void => {
    setVisibleArchiveDialog(false);
    resetArchiveProject();
  };

  const onFormChange = useCallback((): void => {
    const values = form.getFieldsValue();
    resetUpdateProject();

    const initialData = initData();

    if (values.name === initialData.name && values.description === initialData.description) {
      setPreventNavigation(false);
      return;
    }

    setPreventNavigation(true);
  }, [form, initData, resetUpdateProject]);

  const onFormSubmit = (values: FormType): void => {
    setPreventNavigation(false);
    const { name, description } = values;
    const { id, version } = project;
    updateProjectMutation(
      {
        id,
        name: name.trim(),
        description,
        version,
      },
      {
        onError: () => {
          setPreventNavigation(true);
        },
      },
    );
  };
  const onUpdateLocalPermissions = async (mappedUsers: MappedUserDTO[]): Promise<void> => {
    const usersToRemove: AccessGroupMappingRequest = {
      entityId: projectId,
      entityType: 'PROJECT',
      mapping: [],
    };
    const usersToAdd: AccessGroupMappingRequest = {
      entityId: projectId,
      entityType: 'PROJECT',
      mapping: [],
    };

    mappedUsers.forEach(({ id: userId, accessGroups }) => {
      const userInOldMapping = localMappedUsers.find((localMappedUser) => localMappedUser.id === userId);
      if (!userInOldMapping) {
        usersToAdd.mapping.push({ userId, accessGroupIds: accessGroups.map(({ id }) => id) });
      } else {
        accessGroups.forEach(({ id: accessGroupId }) => {
          if (!userInOldMapping.accessGroups.some(({ id }) => id === accessGroupId)) {
            const addedUser = usersToAdd.mapping.find(({ userId: addedUserId }) => addedUserId === userId);
            if (addedUser) {
              addedUser.accessGroupIds.push(accessGroupId);
            } else {
              usersToAdd.mapping.push({ userId, accessGroupIds: [accessGroupId] });
            }
          }
        });
      }
    });

    localMappedUsers.forEach(({ id: userId, accessGroups }) => {
      const userInNewMapping = mappedUsers.find((user) => user.id === userId);

      if (!userInNewMapping) {
        usersToRemove.mapping.push({ userId, accessGroupIds: accessGroups.map(({ id }) => id) });
      } else {
        accessGroups.forEach(({ id: accessGroupId }) => {
          if (!userInNewMapping.accessGroups.some(({ id }) => id === accessGroupId)) {
            const removedUser = usersToRemove.mapping.find(({ userId: addedUserId }) => addedUserId === userId);
            if (removedUser) {
              removedUser.accessGroupIds.push(accessGroupId);
            } else {
              usersToRemove.mapping.push({ userId, accessGroupIds: [accessGroupId] });
            }
          }
        });
      }
    });

    if (usersToAdd.mapping.length) {
      addLocalMappingMutation(usersToAdd, {
        onSuccess: () => {
          if (!usersToRemove.mapping.length) {
            refetchLocalMappedUsers();
            setLocalPermissionsModal(false);
          }
        },
      });
    }

    if (usersToRemove.mapping.length) {
      removeLocalMappingMutation(usersToRemove, {
        onSuccess: () => {
          refetchLocalMappedUsers();
          setLocalPermissionsModal(false);
        },
      });
    }
  };

  return (
    <CreateUpdateFormHolder
      title={t('EditProjectForm.heading')}
      onCancel={() => navigate(pathKeys.project.root({ accountId }))}
      subtitle={account?.name}
      onFormSubmit={onFormSubmit}
      isLoading={isUpdating}
      editMode
      onClickArchiveButton={() => setVisibleArchiveDialog(true)}
      initialData={initData()}
      customFormControl={[form]}
      onFormChange={onFormChange}
      preventNavigation={preventNavigation}
      hideDeleteBtn={!canArchiveProject}
    >
      <ProjectCreateUpdateFormItems isLoading={isUpdating} />
      {canLocalMapProject && (
        <Space direction="vertical">
          <Space>
            <h4>{t('ProjectUpdateForm.localMapping.title')}</h4>
            <Button type="primary" onClick={() => setLocalPermissionsModal(true)}>
              {t('ProjectUpdateForm.localMapping.triggerButton.label')}
            </Button>
          </Space>
          <div className={styles.localPermissions}>
            <UserListWithGroup entityId={projectId} entityType="PROJECT" />
          </div>
        </Space>
      )}
      <ArchiveEntityModal
        open={visibleArchiveDialog}
        isLoading={isArchiving}
        type={
          hasArchivingErrors && archivingErrors?.response?.data.code === 'PositionHasUnarchivedAssignmentException'
            ? 'failed'
            : 'normal'
        }
        closeModal={closeModal}
        archiveEntity={archiveProject}
        normalText={t('ProjectUpdateForm.ArchiveEntityModal.normal.body.text')}
        failedText={t('ProjectUpdateForm.ArchiveEntityModal.withAssignmentIn.body.text')}
      />
      {canLocalMapProject && (
        <LocalPermissionMapper
          entityId={projectId}
          entityType="PROJECT"
          open={localPermissionsModal}
          close={() => setLocalPermissionsModal(false)}
          title={t('ProjectUpdateForm.localMapping.modal.title', {
            name: project?.name,
          })}
          onSave={onUpdateLocalPermissions}
          isSaving={isLocalMappingAdding || isLocalMappingRemoving}
        />
      )}
    </CreateUpdateFormHolder>
  );
};

export default withSuspense(ProjectUpdateForm, { fallback: <Loader /> });
