import React, { ReactNode } from 'react';
import { Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';

// TODO: Bad practice. Revalidate layouts
// eslint-disable-next-line boundaries/element-types
import { getDefaultRedirectPath } from 'entities/session';
import { BackButton } from 'shared/ui/BackButton';
import { Breadcrumb, IBreadcrumbItem } from 'shared/ui/Breadcrumb';
import { LinkButton } from 'shared/ui/LinkButton';

import styles from './ListPageLayout.module.scss';

export type ListPageLayoutProps = {
  title: string;
  breadcrumb: IBreadcrumbItem[];
  isBreadcrumbLoading?: boolean;
  createButtonText?: string;
  createPath?: (...rest: string[]) => string;
  children: ReactNode;
  hideCreateButton?: boolean;
  checkBoxMenu?: ReactNode;
};

const ListPageLayout: React.FC<ListPageLayoutProps> = (props) => {
  const {
    children,
    title,
    breadcrumb,
    isBreadcrumbLoading = false,
    createButtonText,
    hideCreateButton,
    createPath,
    checkBoxMenu,
  } = props;

  const navigate = useNavigate();

  const navigateBreadcrumbBack = (): void => {
    const breadcrumbLength = breadcrumb.length;
    if (breadcrumbLength === 1) {
      navigate(getDefaultRedirectPath());
      return;
    }
    navigate(breadcrumb[breadcrumbLength - 2].path as string);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.leftBlock}>
          <div className={styles.backIcon}>
            <BackButton onClick={navigateBreadcrumbBack} isDisabled={isBreadcrumbLoading} />
          </div>
          <div>
            <h2>{title}</h2>
            <Skeleton
              active
              paragraph={{ rows: 0 }}
              title={{ width: 320 }}
              className={styles.breadcrumbSkeleton}
              loading={isBreadcrumbLoading}
            >
              <div className={styles.breadcrumbWrapper}>
                <Breadcrumb items={breadcrumb} />
              </div>
            </Skeleton>
          </div>
        </div>
        {!hideCreateButton && createPath && (
          <LinkButton linkProps={{ to: createPath() }} buttonProps={{ type: 'primary' }} data-testid="create-button">
            {createButtonText}
          </LinkButton>
        )}
      </div>
      {checkBoxMenu}
      <div className={styles.children}>{children}</div>
    </div>
  );
};

export default ListPageLayout;
