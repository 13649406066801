import { useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

import { hasToken } from 'entities/session';
import { LocalStorageService } from 'shared/lib/localStorage';
import { pathKeys } from 'shared/lib/react-router';
import { Maybe } from 'shared/types';

import { DEFAULT_LOGOUT_NOTIFICATION_TIMEOUT, DEFAULT_LOGOUT_TIMEOUT, LOGOUT_TIME_KEY, TIMER_OFFSET } from './const';
import { getLogoutTime } from './lib/getLogoutTime';
import { addInputListener, removeInputListener } from './lib/InputListener';
import { startTimer } from './lib/startTimer';
import { updateLogoutTime } from './lib/updateLogoutTime';
import { useLocationChange } from './lib/useLocationChange';
import { useLogoutWarningNotification } from './lib/useLogoutWarningNotification';
import { useRequestListener } from './lib/useRequestListener';

export const useSessionTimer = (): void => {
  const navigate = useNavigate();

  const { showNotification, destroyNotification } = useLogoutWarningNotification();

  const { enbableLocationListener } = useLocationChange();
  const { enableReuqestListener } = useRequestListener();

  const isAuthorized = hasToken();

  const timer = useRef<Maybe<NodeJS.Timeout>>(null);
  const notificationTimer = useRef<Maybe<NodeJS.Timer>>(null);

  const clearAll = (): void => {
    if (timer.current) clearTimeout(timer.current);
    if (notificationTimer.current) clearTimeout(notificationTimer.current);

    LocalStorageService.clear(LOGOUT_TIME_KEY);
  };

  const endSession = (): void => {
    clearAll();
    enbableLocationListener(false);
    enableReuqestListener(false);
    navigate(pathKeys.logout());
  };

  const expandSession = (timerCallback: () => void): void => {
    if (notificationTimer.current) clearInterval(notificationTimer.current);
    updateLogoutTime(dayjs().add(DEFAULT_LOGOUT_TIMEOUT, 'ms'));
    startTimer(timerCallback);
    destroyNotification();
  };

  const handlePreLogoutTimerEnd = (): void => {
    const logoutTime = getLogoutTime();

    if (!logoutTime) {
      endSession();
      clearAll();
      return;
    }

    const currentTime = dayjs();
    const difference = logoutTime.diff(currentTime, 'ms');

    if (difference > DEFAULT_LOGOUT_NOTIFICATION_TIMEOUT + TIMER_OFFSET) {
      startTimer(handlePreLogoutTimerEnd, logoutTime);
      return;
    }

    // show notification
    notificationTimer.current = showNotification(
      () => expandSession(handlePreLogoutTimerEnd),
      () => {
        if (notificationTimer.current) clearInterval(notificationTimer.current);
        endSession();
        destroyNotification();
        clearAll();
      },
    );
  };

  useEffect(() => {
    // * Entry point
    const logoutTime = getLogoutTime();

    const isTimeExpired = !!logoutTime && logoutTime.isBefore(dayjs());

    // ? if time expired, navigate to logout
    if (isTimeExpired && isAuthorized) {
      endSession();
    } else if (isTimeExpired) {
      clearAll();
    }

    if (isAuthorized && !isTimeExpired) {
      updateLogoutTime(dayjs().add(DEFAULT_LOGOUT_TIMEOUT, 'ms'));
      timer.current = startTimer(handlePreLogoutTimerEnd);
      addInputListener();
      enbableLocationListener(true);
      enableReuqestListener(true);
    }

    return () => {
      if (timer.current) clearTimeout(timer.current);
      removeInputListener();
      enbableLocationListener(false);
      enableReuqestListener(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized]);
};

export default useSessionTimer;
