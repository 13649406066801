import { useState } from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { SettingService } from 'features/userSettings';
import { DEFAULT_IS_HIDDEN_ARCHIVED_PROJECTS, DEFAULT_IS_HIDDEN_EXPIRED_PROJECTS } from '../Project.consts';

type ReturnTypeUseProjectListParams = {
  hideNonActive: boolean;
  setPage: (value: number) => void;
  page: number;
  showArchived: boolean;
  onChangeHiddenArchivedProjects: (event: CheckboxChangeEvent) => void;
  onChangeHiddenExpiredProjects: (event: CheckboxChangeEvent) => void;
};
const useProjectListParams = (): ReturnTypeUseProjectListParams => {
  const [page, setPage] = useState(1);
  const [hideNonActive, setHideNonActive] = useState(
    SettingService.getLocalSetting('hideNonActiveProjects') ?? DEFAULT_IS_HIDDEN_EXPIRED_PROJECTS,
  );
  const [showArchived, setShowArchived] = useState(
    SettingService.getLocalSetting('showArchivedProjects') ?? DEFAULT_IS_HIDDEN_ARCHIVED_PROJECTS,
  );

  const onChangeHiddenExpiredProjects = (event: CheckboxChangeEvent): void => {
    SettingService.saveLocalSetting('hideNonActiveProjects', event.target.checked);

    setHideNonActive(event.target.checked);
    if (page !== 1) {
      setPage(1);
    }
  };

  const onChangeHiddenArchivedProjects = (event: CheckboxChangeEvent): void => {
    SettingService.saveLocalSetting('showArchivedProjects', event.target.checked);
    setShowArchived(event.target.checked);
    if (page !== 1) {
      setPage(1);
    }
  };

  return {
    hideNonActive,
    setPage,
    page,
    showArchived,
    onChangeHiddenExpiredProjects,
    onChangeHiddenArchivedProjects,
  };
};

export { useProjectListParams };
