import { createElement } from 'react';
import { RouteObject, redirect } from 'react-router-dom';

import { isHasAccess, pathPermissions } from 'features/validateAccess';
import { accessGroupsService } from 'entities/accessGroup';
import { permissionListService } from 'entities/permission/';
import { pathKeys } from 'shared/lib/react-router';

import AccessGroupsManagementPage from './AccessGroupsManagementPage';

export const accessGroupsManagementPageRoute: RouteObject = {
  path: pathKeys.accessGroups(),
  element: createElement(AccessGroupsManagementPage),
  loader: async (args) => {
    if (!isHasAccess(pathPermissions.accessGroups)) {
      return redirect(pathKeys.dashboard());
    }

    Promise.all([accessGroupsService.prefetchQuery(), permissionListService.prefetchQuery()]);

    return args;
  },
};
