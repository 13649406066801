import React, { useCallback, useEffect, useState } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useForm } from 'antd/lib/form/Form';
import { Store } from 'antd/lib/form/interface';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ArchiveEntityModal } from 'features/archiveEntity';
import { isHasAccess } from 'features/validateAccess';
import {
  demandParentInfoService,
  demandService,
  useArchiveDemandMutation,
  useUpdateDemandMutation,
} from 'entities/demand';
import { positionService } from 'entities/position';

import { DEFAULT_DATE_FORMAT_FOR_REQUESTS } from 'shared/config';
import { convertDayJsToString, convertToDayJs } from 'shared/lib/dayjs';
import { withSuspense } from 'shared/lib/react';
import { pathKeys } from 'shared/lib/react-router';
import { Loader } from 'shared/ui/Loader';

import CreateUpdateFormHolder from 'components/CreateUpdateFormHolder/CreateUpdateFormHolder';
import { DEFAULT_STATUS_VALUE } from 'components/Demand/Demand.consts';
import DemandCreateUpdateFormItems from 'components/Demand/DemandCreateUpdateFormItems/DemandCreateUpdateFormItems';

import { DEMAND_RECRUITER_CHANGE_PERMISSIONS, getDemandArchivePermissions } from '../Demand.permissions';

type DemandCreateFormProps = {
  accountId: string;
  projectId: string;
  positionId: string;
  demandId: string;
};

const DemandUpdateForm: React.FC<DemandCreateFormProps> = ({ accountId, projectId, positionId, demandId }) => {
  const { t } = useTranslation();

  const [visibleDemandDialog, setVisibleDemandDialog] = useState(false);

  const [form] = useForm();

  const navigate = useNavigate();

  const [preventNavigation, setPreventNavigation] = useState(false);

  const canArchiveDemand = isHasAccess(getDemandArchivePermissions(accountId, projectId));

  const { data: position } = useSuspenseQuery(positionService.queryOptions(positionId));

  const { data: demandParentInfo } = useSuspenseQuery(demandParentInfoService.queryOptions(positionId));
  const { data: demand } = useSuspenseQuery(demandService.queryOptions(demandId));

  const {
    mutateAsync: updateDemandMutation,
    isError: hasUpdateErrors,
    isPending: isUpdating,
  } = useUpdateDemandMutation(accountId, projectId, demandId);

  const {
    mutateAsync: demandArchiveMutation,
    isPending: isArchiving,
    reset: resetDemandMutation,
  } = useArchiveDemandMutation(accountId, projectId, demandId);

  const disableChangingRecruiter = !isHasAccess(DEMAND_RECRUITER_CHANGE_PERMISSIONS);

  const initData = useCallback((): Store => {
    if (demandId !== demand?.id) {
      return {
        status: DEFAULT_STATUS_VALUE,
      };
    }

    const { status, desirableDate, recruiter } = demand;

    return {
      status,
      desirableDate: convertToDayJs(desirableDate),
      recruiter,
    };
  }, [demandId, demand]);

  const creationDate = useCallback((): Dayjs => {
    return dayjs(demand?.createdAt);
  }, [demand]);

  const checkFormValuesModified = useCallback((): void => {
    const values = form.getFieldsValue();
    const initialData = initData();

    const isRecruiterChanged = values.recruiter?.id !== initialData.recruiter?.id;
    const isStatusChanged = values.status !== initialData.status;
    const isDesirableDateChanged =
      dayjs.isDayjs(values.desirableDate) && dayjs.isDayjs(initialData.desirableDate)
        ? !dayjs(initialData.desirableDate).isSame(values.desirableDate, 'date')
        : values.desirableDate !== initialData.desirableDate;

    setPreventNavigation(isStatusChanged || isDesirableDateChanged || isRecruiterChanged);
  }, [form, initData]);

  useEffect(() => {
    if (hasUpdateErrors) {
      checkFormValuesModified();
    }
  }, [checkFormValuesModified, hasUpdateErrors]);

  const closeModal = (): void => {
    setVisibleDemandDialog(false);
    resetDemandMutation();
  };

  const archiveDemand = (): void => {
    setPreventNavigation(false);
    demandArchiveMutation(demand.id);
  };

  const onFormSubmit = (values: Store): void => {
    setPreventNavigation(false);
    const { desirableDate, status, recruiter } = values;
    if (!demand) return;
    const { id, version } = demand;

    const demandDesirableDate = convertDayJsToString(desirableDate, DEFAULT_DATE_FORMAT_FOR_REQUESTS);

    updateDemandMutation(
      {
        id,
        status,
        recruiterId: recruiter ? recruiter.id : null,
        desirableDate: demandDesirableDate,
        version,
      },
      {
        onError: () => {
          setPreventNavigation(true);
        },
      },
    );
  };

  return (
    <div data-testid="demand-update-form">
      <CreateUpdateFormHolder
        title={t('Demand:DemandUpdateForm.heading')}
        subtitle={position?.name ?? ''}
        onFormSubmit={onFormSubmit}
        onCancel={() => navigate(pathKeys.position.root({ accountId, projectId }))}
        isLoading={isUpdating}
        customFormControl={[form]}
        onFormChange={checkFormValuesModified}
        preventNavigation={preventNavigation}
        initialData={initData()}
        onClickArchiveButton={() => setVisibleDemandDialog(true)}
        editMode
        hideDeleteBtn={!canArchiveDemand}
      >
        <DemandCreateUpdateFormItems
          isLoading={isUpdating}
          positionStartDate={convertToDayJs(position?.startDate)}
          positionEndDate={convertToDayJs(position?.endDate)}
          accountDescription={demandParentInfo.accountDescription || ''}
          projectDescription={demandParentInfo.projectDescription || ''}
          positionDescription={demandParentInfo.positionDescription || ''}
          creationDate={creationDate()}
          disableRecruiterChanging={disableChangingRecruiter}
          forceOnFormChange={checkFormValuesModified}
        />
        <ArchiveEntityModal
          open={visibleDemandDialog}
          isLoading={isArchiving}
          type="normal"
          closeModal={closeModal}
          archiveEntity={archiveDemand}
          normalText={t('Demand:DemandUpdateForm.archiveModal.archive.body.text')}
        />
      </CreateUpdateFormHolder>
    </div>
  );
};

export default withSuspense(DemandUpdateForm, { fallback: <Loader /> });
