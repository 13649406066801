import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCreateAccountMutation } from 'entities/account';

import { pathKeys } from 'shared/lib/react-router';

import AccountCreateUpdateFormItems from 'components/Account/AccountCreateUpdateFormItems/AccountCreateUpdateFormItems';
import CreateUpdateFormHolder from 'components/CreateUpdateFormHolder/CreateUpdateFormHolder';

type FormType = {
  name: string;
  description: string;
  logo: {
    url: string;
  }[];
};

const AccountCreateForm: React.FC = () => {
  const { t } = useTranslation();
  const [form] = useForm<FormType>();

  const {
    mutateAsync: createMutation,
    isPending: isCreating,
    isError: hasCreatingErrors,
    error: creatingErrors,
    reset,
  } = useCreateAccountMutation();

  useEffect(() => {
    if (hasCreatingErrors && creatingErrors.response?.data.code === 'ItemAlreadyExistsException') {
      form.setFields([
        { name: 'name', errors: [t('Account:AccountCreateUpdateFormItems.name.item.validationMessage.nonUniqueName')] },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCreatingErrors]);

  const navigate = useNavigate();

  const [preventNavigation, setPreventNavigation] = useState(false);

  const onFormChange = useCallback((): void => {
    const values = form.getFieldsValue();
    reset();

    setPreventNavigation(!!values.name || !!values.description);
  }, [form, reset]);

  const onFormSubmit = ({ name, description, logo }: FormType): void => {
    setPreventNavigation(false);

    const logoUrl = logo?.[0]?.url;
    createMutation(
      { name: name.trim(), description, logo: logoUrl },
      {
        onError: () => {
          setPreventNavigation(true);
        },
      },
    );
  };

  return (
    <CreateUpdateFormHolder
      title={t('Account:AccountCreateForm.heading')}
      onFormSubmit={onFormSubmit}
      onCancel={() => navigate(pathKeys.account.root())}
      isLoading={isCreating}
      preventNavigation={preventNavigation}
      onFormChange={onFormChange}
      customFormControl={[form]}
    >
      <AccountCreateUpdateFormItems isLoading={isCreating} />
    </CreateUpdateFormHolder>
  );
};

export default AccountCreateForm;
