import dayjs from 'dayjs';
import { DEFAULT_LOGOUT_NOTIFICATION_TIMEOUT, DEFAULT_LOGOUT_TIMEOUT, TIMER_OFFSET } from '../const';

export const startTimer = (callback: () => void, newLogoutTime?: dayjs.Dayjs): NodeJS.Timer => {
  const currentTime = dayjs();
  const logoutTime = newLogoutTime ?? currentTime.add(DEFAULT_LOGOUT_TIMEOUT);

  const notificationTime = logoutTime.diff(currentTime, 'ms') - DEFAULT_LOGOUT_NOTIFICATION_TIMEOUT - TIMER_OFFSET;
  return setTimeout(callback, notificationTime);
};
