import { useState } from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { SettingService } from 'features/userSettings';
import { DEFAULT_IS_HIDDEN_ARCHIVED_ACCOUNTS, DEFAULT_IS_HIDDEN_EXPIRED_ACCOUNTS } from '../Account.const';

type ReturnTypeUseAccountListParams = {
  onChangeShowArchived: (event: CheckboxChangeEvent) => void;
  hideNonActive: boolean;
  setPage: (value: number) => void;
  page: number;
  showArchived: boolean;
  onChangeHiddenExpiredAccounts: (event: CheckboxChangeEvent) => void;
};
const useAccountListParams = (): ReturnTypeUseAccountListParams => {
  const [page, setPage] = useState(1);
  const [hideNonActive, setHideNonActive] = useState<boolean>(
    SettingService.getLocalSetting('hideNonActiveAccounts') ?? DEFAULT_IS_HIDDEN_EXPIRED_ACCOUNTS,
  );
  const [showArchived, setShowArchived] = useState<boolean>(
    SettingService.getLocalSetting('showArchivedAccounts') ?? DEFAULT_IS_HIDDEN_ARCHIVED_ACCOUNTS,
  );

  const onChangeHiddenExpiredAccounts = (event: CheckboxChangeEvent): void => {
    SettingService.saveLocalSetting('hideNonActiveAccounts', event.target.checked);
    setHideNonActive(event.target.checked);
    if (page !== 1) {
      setPage(1);
    }
  };

  const onChangeShowArchived = (event: CheckboxChangeEvent): void => {
    SettingService.saveLocalSetting('showArchivedAccounts', event.target.checked);
    setShowArchived(event.target.checked);
    if (page !== 1) {
      setPage(1);
    }
  };

  return {
    onChangeShowArchived,
    hideNonActive,
    setPage,
    page,
    showArchived,
    onChangeHiddenExpiredAccounts,
  };
};

export { useAccountListParams };
