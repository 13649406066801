import React, { useCallback, useEffect, useState } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { Form } from 'antd';
import { Store } from 'antd/lib/form/interface';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCreateAssignmentMutation } from 'entities/assignment';
import { positionService } from 'entities/position';

import { DEFAULT_DATE_FORMAT_FOR_REQUESTS } from 'shared/config';
import { convertDayJsToString } from 'shared/lib/dayjs';
import { withSuspense } from 'shared/lib/react';

import { pathKeys } from 'shared/lib/react-router';
import { Loader } from 'shared/ui/Loader';
// eslint-disable-next-line max-len
import AssignmentCreateUpdateFormItems from 'components/Assignment/AssignmentCreateUpdateFormItems/AssignmentCreateUpdateFormItems';
import CreateUpdateFormHolder from 'components/CreateUpdateFormHolder/CreateUpdateFormHolder';

type AssignmentCreateFormType = { accountId: string; projectId: string; positionId: string };

const AssignmentCreateForm: React.FC<AssignmentCreateFormType> = ({ accountId, projectId, positionId }) => {
  const { t } = useTranslation();

  const [preventNavigation, setPreventNavigation] = useState(false);

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const { data: position } = useSuspenseQuery(positionService.queryOptions(positionId));

  const {
    mutateAsync: createAssignmentMutation,
    isPending: isCreating,
    isError: hasCreatingErrors,
    error: creatingErrors,
  } = useCreateAssignmentMutation(accountId, projectId);

  const checkFormValuesModified = useCallback((): void => {
    const values = form.getFieldsValue();

    const isUserChanged = !!values.user;
    const isDatesChanged = Array.isArray(values.date);
    const isInvolvementChanged = !!values.involvement;
    const isBilledInvolvementChanged = !!values.billedInvolvement;

    setPreventNavigation(isUserChanged || isDatesChanged || isInvolvementChanged || isBilledInvolvementChanged);
  }, [form]);

  useEffect(() => {
    if (hasCreatingErrors) {
      checkFormValuesModified();
    }
  }, [hasCreatingErrors, checkFormValuesModified]);

  const onFormSubmit = (values: Store): void => {
    const { user, date, involvement, billedInvolvement } = values;
    const startDate = convertDayJsToString(date[0], DEFAULT_DATE_FORMAT_FOR_REQUESTS);
    const endDate = convertDayJsToString(date[1], DEFAULT_DATE_FORMAT_FOR_REQUESTS);

    setPreventNavigation(false);

    createAssignmentMutation({
      positionId,
      userId: user,
      startDate,
      endDate,
      involvement,
      billedInvolvement,
    });
  };

  return (
    <CreateUpdateFormHolder
      title={t('Assignment:AssignmentCreateForm.heading')}
      subtitle={position?.name}
      onFormSubmit={onFormSubmit}
      onCancel={() => navigate(pathKeys.position.root({ accountId, projectId }))}
      isLoading={isCreating}
      customFormControl={[form]}
      onFormChange={checkFormValuesModified}
      preventNavigation={preventNavigation}
    >
      <AssignmentCreateUpdateFormItems
        isLoading={isCreating}
        isDatesIntersected={creatingErrors?.response?.data.code === 'PositionAlreadyAssignedException'}
        onChangeFn={checkFormValuesModified}
        position={position}
      />
    </CreateUpdateFormHolder>
  );
};

export default withSuspense(AssignmentCreateForm, { fallback: <Loader /> });
