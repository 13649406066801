import { useCallback, useEffect } from 'react';
import { App } from 'antd';
import { useTranslation } from 'react-i18next';
import { Blocker, useBlocker } from 'react-router-dom';

import { pathKeys } from './config';

export const useBlockNavigation = (block: boolean): Blocker => {
  const { t } = useTranslation('ReusableComponents');

  const { modal } = App.useApp();

  const blocker = useBlocker(({ nextLocation }) => {
    const isLogOutPage = nextLocation.pathname === pathKeys.logout();
    return block && !isLogOutPage;
  });

  const handleChangeBrowserRoute = useCallback(
    (event: BeforeUnloadEvent): void => {
      if (block) {
        event.preventDefault();
      }
    },
    [block],
  );

  useEffect(() => {
    window.addEventListener('beforeunload', handleChangeBrowserRoute);
    return () => window.removeEventListener('beforeunload', handleChangeBrowserRoute);
  }, [handleChangeBrowserRoute]);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      modal.confirm({
        centered: true,
        content: t('ReusableComponents:CreateUpdateFormHolder.confirmPageLeavingModal.body.text'),
        okText: t('ReusableComponents:CreateUpdateFormHolder.confirmPageLeavingModal.button.yes.text'),
        onOk: () => {
          blocker.proceed();
        },
        onCancel: () => blocker.reset?.(),
        destroyOnClose: true,
        closable: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocker.state, modal, t]);

  const extendedBlocker = {
    ...blocker,
    reset() {
      blocker.reset?.();
      window.removeEventListener('beforeunload', handleChangeBrowserRoute);
    },
  } as Blocker;

  return extendedBlocker;
};
