import React from 'react';
import { useTranslation } from 'react-i18next';

import { isHasAccess } from 'features/validateAccess';
import { ListPageLayout } from 'shared/layouts/pageLevelLayouts';
import { pathKeys } from 'shared/lib/react-router';
import type { IBreadcrumbItem } from 'shared/ui/Breadcrumb';
import AccountCheckBoxMenu from 'components/Account/AccountCheckBoxMenu/AccountCheckBoxMenu';
import { useAccountListParams } from 'components/Account/AccountCheckBoxMenu/useAccountListParams';
import AccountsList from 'components/Account/AccountsList/AccountsList';

import { CREATE_ACCOUNT } from './AccountPage.permissions';

const AccountsPage: React.FC = () => {
  const { t } = useTranslation();

  const { onChangeShowArchived, hideNonActive, setPage, page, showArchived, onChangeHiddenExpiredAccounts } =
    useAccountListParams();

  const createBreadcrumb = (): IBreadcrumbItem[] => {
    return [
      {
        label: t('Account:AccountsPage.heading'),
      },
    ];
  };

  const hideCreateButton = !isHasAccess(CREATE_ACCOUNT);

  return (
    <ListPageLayout
      title={t('Account:AccountsPage.heading')}
      breadcrumb={createBreadcrumb()}
      createButtonText={t('Account:AccountsPage.createButton.label')}
      createPath={pathKeys.account.create}
      hideCreateButton={hideCreateButton}
      checkBoxMenu={
        <AccountCheckBoxMenu
          onChangeShowArchived={onChangeShowArchived}
          hideNonActive={hideNonActive}
          showArchived={showArchived}
          onChangeHiddenExpiredAccounts={onChangeHiddenExpiredAccounts}
        />
      }
    >
      <AccountsList showArchived={showArchived} page={page} setPage={setPage} hideNonActive={hideNonActive} />
    </ListPageLayout>
  );
};

export default AccountsPage;
