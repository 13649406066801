import React, { ReactNode } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { isHasAccess } from 'features/validateAccess';
import { accountsService } from 'entities/account';

import { withSuspense } from 'shared/lib/react';
import { pathKeys } from 'shared/lib/react-router';
import { Avatar } from 'shared/ui/Avatar';

import { PAGE_SIZE } from 'components/Account/Account.const';

import {
  getLocalEditAccountPermission,
  getLocalReadProjectPermission,
  GLOBAL_EDIT_ACCOUNT_PERMISSION,
  GLOBAL_PROJECT_READ_PERMISSION,
} from 'components/Account/Account.permissions';

import ListWithPagination from 'components/ListWithPagination/ListWithPagination';
import RowItem from 'components/RowItem/RowItem';
import { CommonSkeletonList } from 'components/SkeletonList/commonSkeletonList/CommonSkeletonList';

interface AccountsListProps {
  showArchived: boolean;
  page: number;
  setPage: (value: number) => void;
  hideNonActive: boolean;
}

const AccountsList = ({ showArchived, page, setPage, hideNonActive }: AccountsListProps): JSX.Element => {
  const { t } = useTranslation();

  const {
    data: { content: accountList, ...accountListAux },
  } = useSuspenseQuery(
    accountsService.queryOptions({
      page: page - 1,
      size: PAGE_SIZE,
      showArchived,
      hideNonActive,
    }),
  );

  const canEditAllAccounts = isHasAccess(GLOBAL_EDIT_ACCOUNT_PERMISSION);
  const canSeeAllProjects = isHasAccess(GLOBAL_PROJECT_READ_PERMISSION);

  const renderListItems = (): ReactNode[] => {
    return accountList.map((account) => {
      const canEditAccount = canEditAllAccounts || isHasAccess(getLocalEditAccountPermission(account.id));
      const canSeeProjects = canSeeAllProjects || isHasAccess(getLocalReadProjectPermission(account.id));

      return (
        <RowItem
          key={account.id}
          name={account.name}
          count={account.projectsCount}
          openLinkHref={pathKeys.project.root({ accountId: account.id })}
          editLinkHref={pathKeys.account.update({ accountId: account.id })}
          countName={t('Account:AccountsList.projectsCount.name')}
          logo={<Avatar size={32} text={account.name} picture={account.logoUrl} />}
          isArchived={!!account.archivedAt}
          iconTooltip={t('Account:AccountsList.values.archived')}
          hideEditButton={!canEditAccount}
          disableLink={!canSeeProjects}
        />
      );
    });
  };

  return (
    <ListWithPagination
      items={renderListItems()}
      totalItems={accountListAux?.totalElements ?? 0}
      pageSize={PAGE_SIZE}
      page={page}
      setPage={setPage}
    />
  );
};

export default withSuspense(AccountsList, {
  fallback: <CommonSkeletonList />,
});
