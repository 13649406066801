import React, { FC, Fragment, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { isHasAccess } from 'features/validateAccess';
import { ModalDialog } from 'shared/ui/ModalDialog';

import { getUsersIds } from '../../lib/getUsersIds';
import { useTransferModalStore } from '../../model/store';
import { CAN_SELECT_USERS } from '../../permissions';

import { DiscardSelectedUsersOverlay } from '../DiscardSelectedUsersOverlay/DiscardSelectedUsersOverlay';
import Transfer from '../Transfer/Transfer';

import styles from './TransferModal.module.scss';

type TransferModalProps = {
  onSave: (groupId: string, usersIdsToMoveIn: string[], usersIdsToMoveOut: string[]) => void;
  onClose: () => void;
};
const TransferModal: FC<TransferModalProps> = ({ onClose, onSave }) => {
  const { t } = useTranslation('AccessGroup');

  const [discardSelectedUsersOverlay, setDiscardSelectedUsersOverlay] = useState(false);

  const { isOpen, groupId, resetUsers, usersMovedFromGroup, usersMovedToGroup, selectedFromAll, selectedFromGroup } =
    useTransferModalStore();

  const disableUserSelection = !isHasAccess(CAN_SELECT_USERS);

  const saveChanges = (): void => {
    if (groupId) onSave(groupId, getUsersIds(usersMovedToGroup), getUsersIds(usersMovedFromGroup));
    setDiscardSelectedUsersOverlay(false);
    setTimeout(() => resetUsers(), 700);
  };

  const saveChangesWrapper = (): void => {
    if (selectedFromAll.length > 0 || selectedFromGroup.length > 0) {
      setDiscardSelectedUsersOverlay(true);
      return;
    }
    saveChanges();
  };

  const onCloseWrapper = (): void => {
    onClose();
    resetUsers();
    setDiscardSelectedUsersOverlay(false);
  };

  const includeDiscardChangesOverlay = usersMovedToGroup.length > 0 || usersMovedFromGroup.length > 0;

  return (
    <ModalDialog
      includeDiscardChangesOverlay={includeDiscardChangesOverlay}
      open={isOpen}
      closeFn={onCloseWrapper}
      actionFn={saveChangesWrapper}
      body={
        <Fragment>
          <Transfer disableUserSelection={disableUserSelection} />
          <DiscardSelectedUsersOverlay
            onConfirm={saveChanges}
            onCancel={() => setDiscardSelectedUsersOverlay(false)}
            visible={discardSelectedUsersOverlay}
          />
        </Fragment>
      }
      okButtonProps={{
        type: 'primary',
        size: 'large',
        className: classNames(styles.saveButton, {
          [styles.hideButton]: disableUserSelection,
        }),
      }}
      cancelButtonProps={{
        type: 'default',
        size: 'large',
      }}
      width={1200}
      okText={t('Transfer.saveButton.label')}
      cancelText={t('Transfer.closeButton.label')}
      classNames={{ content: styles.modalContent }}
    />
  );
};

export default React.memo(TransferModal);
