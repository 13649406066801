import React, { useEffect, useState } from 'react';
import { Select } from 'antd';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { type GrantType } from 'entities/accessGroup';

import { ReactComponent as ArrowBottom } from 'shared/assets/icons/dynamicColor/arrow-bottom.svg';
import { Maybe } from 'shared/types';

import styles from './GrantTypeSelector.module.scss';

export type GrantTypeSelectorProps = {
  disabled?: boolean;
  initialGrantType: Maybe<GrantType>;
  onChangeGrantType: (permissionValue: GrantType) => void;
  allowedGrantTypes: GrantType[];
};

const GrantTypeSelector = ({
  disabled,
  initialGrantType,
  onChangeGrantType,
  allowedGrantTypes,
}: GrantTypeSelectorProps): JSX.Element => {
  const [selectedOption, setSelectedOption] = useState(initialGrantType);
  const { t } = useTranslation();

  useEffect(() => {
    if (disabled && selectedOption !== initialGrantType) {
      setSelectedOption(initialGrantType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled, initialGrantType]);

  const onChange = (value: GrantType): void => {
    setSelectedOption(value);
    onChangeGrantType(value);
  };

  const options = allowedGrantTypes.map((grantType) => {
    return {
      value: grantType,
      label: t(`AccessGroup:GrantTypeSelector.options.${grantType}`),
    };
  });

  return (
    <div data-testid="grant-type-selector">
      <Select
        disabled={disabled}
        className={classnames(styles.selectWrapper, {
          [styles.colorView]: selectedOption === 'VIEW',
          [styles.colorNone]: selectedOption === 'NONE',
          [styles.colorEdit]: selectedOption === 'EDIT',
          [styles.colorMap]: selectedOption === 'MAP',
        })}
        data-testid="select-settings"
        defaultValue={initialGrantType}
        value={selectedOption}
        onChange={onChange}
        popupClassName={styles.settingSelectDropdown}
        suffixIcon={
          <div className={styles.settingIcon}>
            <div className={styles.showChanged}>
              {initialGrantType !== selectedOption ? t('AccessGroup:GrantTypeSelector.status.changed.text') : ''}
            </div>
            <ArrowBottom width="7px" />
          </div>
        }
        options={options}
      />
    </div>
  );
};

export default React.memo(GrantTypeSelector);
