import { useState } from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { SettingService } from 'features/userSettings';

import { DEFAULT_IS_HIDDEN_EXPIRED_POSITIONS } from '../Position.consts';

type ReturnTypeUsePositionListParams = {
  page: number;
  setPage: (value: number) => void;
  onChangeHiddenExpiredPositions: (event: CheckboxChangeEvent) => void;
  expiredIncluded: boolean;
};

const usePositionListParams = (): ReturnTypeUsePositionListParams => {
  const [page, setPage] = useState(1);

  const [expiredIncluded, setExpiredIncluded] = useState(
    SettingService.getLocalSetting('includeExpiredPositions') ?? DEFAULT_IS_HIDDEN_EXPIRED_POSITIONS,
  );

  const onChangeHiddenExpiredPositions = (event: CheckboxChangeEvent): void => {
    SettingService.saveLocalSetting('includeExpiredPositions', event.target.checked);
    setExpiredIncluded(event.target.checked);
    if (page !== 1) {
      setPage(1);
    }
  };

  return {
    page,
    setPage,
    onChangeHiddenExpiredPositions,
    expiredIncluded,
  };
};

export { usePositionListParams };
