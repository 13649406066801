import React, { ReactNode } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { isHasAccess } from 'features/validateAccess';
import { projectsService } from 'entities/project';
import { withSuspense } from 'shared/lib/react';
import { pathKeys } from 'shared/lib/react-router';
import ListWithPagination from 'components/ListWithPagination/ListWithPagination';
import { PAGE_SIZE } from 'components/Project/Project.consts';
import RowItem from 'components/RowItem/RowItem';
import { CommonSkeletonList } from 'components/SkeletonList/commonSkeletonList/CommonSkeletonList';

import {
  getEditAllProjectPermissions,
  getEditProjectLocalPermission,
  getGlobalReadPositionPermission,
  getLocalReadPositionPermission,
} from '../Project.permissions';

interface ProjectsListProps {
  showArchived: boolean;
  page: number;
  setPage: (value: number) => void;
  hideNonActive: boolean;
  accountId: string;
}

const ProjectsList = ({ showArchived, page, setPage, hideNonActive, accountId }: ProjectsListProps): JSX.Element => {
  const { t } = useTranslation();

  const canEditAllProject = isHasAccess(getEditAllProjectPermissions(accountId));
  const canSeeAllPositions = isHasAccess(getGlobalReadPositionPermission(accountId));

  const {
    data: { content: projectList, ...projectListAuxData },
  } = useSuspenseQuery(
    projectsService.queryOptions({ accountId, page: page - 1, size: PAGE_SIZE, showArchived, hideNonActive }),
  );

  const renderListItems = (): ReactNode[] => {
    return projectList.map((project) => {
      const canEditProject = canEditAllProject || isHasAccess(getEditProjectLocalPermission(project.id));
      const canSeePositions = canSeeAllPositions || isHasAccess(getLocalReadPositionPermission(project.id));

      return (
        <RowItem
          key={project.id}
          name={project.name}
          count={project.positionsCount}
          openLinkHref={pathKeys.position.root({ accountId, projectId: project.id })}
          editLinkHref={pathKeys.project.update({ accountId, projectId: project.id })}
          countName={t('Project:ProjectsList.positionsCount.name')}
          isArchived={project.archived}
          hideEditButton={!canEditProject}
          iconTooltip={t('Project:ProjectsList.values.archived')}
          disableLink={!canSeePositions}
        />
      );
    });
  };

  return (
    <ListWithPagination
      items={renderListItems()}
      totalItems={projectListAuxData?.totalElements ?? 0}
      pageSize={PAGE_SIZE}
      page={page}
      setPage={setPage}
    />
  );
};

export default withSuspense(ProjectsList, {
  fallback: <CommonSkeletonList />,
});
